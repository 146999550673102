.ReactModal__Overlay {
  z-index: 100;
  backdrop-filter: blur(1.5px);
  background-color: transparent !important;
}
body {
  font-family: "Ranade-Variable" !important;
  margin: 0;
  padding: 0;
}

:is(h1, h2, h3, h4, h5, h6) {
  font-family: "Chillax-Variable" !important;
}
