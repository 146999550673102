.TokenVestingApp {
  background: url("../images/bg.jpg");
  text-align: center;
  width: auto;
  height: auto;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.header {
  margin-bottom: 30px;
  padding: 2rem;
  background-color: white;

  text-align: left;
  color: black;
  border-bottom: 1px solid #ecf0f2;
}

.logo {
  position: relative;
  padding-right: 12px;
  border-right: 1px solid #5cb6e4;
}

.contracts {
  display: inline-block;
  height: 50px;
  vertical-align: middle;
  margin-left: 1rem;
  font-family: "Chillax-Medium" !important;
  margin-top: -1rem;
}

.contracts h3 {
  font-size: 20px;
  margin: 5px 0 0;
}

.contracts a {
}

.contracts span {
  color: #333;
  font-size: 11px;
}

.details a {
  cursor: pointer;
  color: #ff9a36;
}

.warning {
  position: relative;
}

.warning-message {
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 50px;

  font-size: 16px;
  text-align: center;
}

.action {
  color: white;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner {
  position: absolute;
  width: 100%;
  min-height: 160vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 200px; */
  z-index: 1;

  backdrop-filter: blur(1.4px);
}

.spinner img {
  height: 45px;
  animation: rotation 1s linear infinite;
}

.spinner-img {
  top: calc(50% - 6rem);
  left: calc(50% - 5.5rem);
  width: 9rem;
  height: 8rem;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.img-div {
  background: linear-gradient(
      318.86deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 53.86%
    ),
    radial-gradient(
      58.26% 58.26% at 0% 103.31%,
      #19d2ff 26.56%,
      rgba(16, 231, 251, 0) 100%
    ),
    radial-gradient(
      67.56% 67.56% at 100% 0%,
      #ffccfb 0%,
      #ff39f0 45.31%,
      rgba(238, 65, 255, 0) 100%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
  filter: blur(79px);
  transform: rotate(-176.18deg);
  width: 15rem;
  height: 10rem;
}
.approve-img {
  position: absolute;
  top: calc(50% - 6rem);
  left: calc(50% - 5.5rem);
  width: 10rem;
  margin-top: 0rem;
}
.failed-img {
  position: absolute;
  top: calc(50% - 6rem);
  left: calc(50% - 5.5rem);
  width: 10rem;
  margin-top: -1rem;
}
.emoji {
  vertical-align: middle;
}

.tx-modal {
  padding: 20px;
  background: #d3caf3;
  width: 500px;
  color: black;
  top: 40%;
  left: 35%;
  z-index: 1;
  position: absolute;
}

th {
  min-width: 155px;
}

.vesting-info-table {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}
.heading {
  font-style: normal;
  font-weight: normal;
  text-align: left;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  font-family: "Chillax-Medium" !important;
  line-height: 35px;
  color: white;
}

.sub-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #ff9a36;
}
.heading-data {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 29px;
  color: #f3f2f9;
}
.card-container {
  /* box-shadow: 0 4px 4px hsla(0, 0%, 76.9%, 0.3); */
  background-image: linear-gradient(
      342deg,
      rgba(50, 27, 159, 0.6) 0%,
      rgba(123, 35, 195, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      rgba(89, 2, 210, 0.6) 0%,
      rgba(133, 66, 244, 0) 110%
    );
  border-radius: 8px;
  padding: 0rem;
  width: 100%;
  opacity: 1.5;
  margin-right: 2rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  border: 1px solid rgba(225, 225, 225, 0.3);
  padding-bottom: 1.2rem;
}

.card-header {
  background: linear-gradient(318.86deg, #321b9f, rgba(123, 35, 195, 0) 53.86%),
    radial-gradient(
      83.68% 83.68% at 0 0,
      #5902d2 0,
      rgba(133, 66, 244, 0) 95.83%
    );
  border-bottom: 1px solid hsla(0, 0%, 88.2%, 0.3);
  box-sizing: border-box;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 4rem;
  margin-bottom: 1.2rem !important;
}
.card-header:first-child {
  border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0 !important;
}
.card-container-main {
  box-shadow: rgba(25, 19, 38, 0.15) 8px 7px 8px;
  background: linear-gradient(
      342deg,
      rgba(50, 27, 159, 0.6) 0%,
      rgba(123, 35, 195, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      rgba(89, 2, 210, 0.6) 0%,
      rgba(133, 66, 244, 0) 110%
    );
  border-radius: 8px;
  /* border: 1px solid rgba(225, 225, 225, 0.3); */
  padding: 2rem 0.5rem;
  opacity: 1.5;
  margin: 0 auto;
  background-repeat: no-repeat;
  width: 45% !important;
  border: 1px solid rgba(225, 225, 225, 0.3);
}
.table-heading {
  margin-left: 2.2rem;
  text-align: left;
}

.PublicButton {
  background: linear-gradient(
      329.79deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 42.95%
    ),
    radial-gradient(
      58.26% 100.26% at 0% 103.31%,
      #ff39f0 26.56%,
      rgba(133, 66, 244, 0) 125%
    ),
    radial-gradient(
      67.56% 100.56% at 100% 0%,
      #fff4cc 0%,
      #ff9a36 45.31%,
      rgba(243, 127, 157, 0.22) 98%
    ),
    radial-gradient(
      83.68% 181.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  color: #ffffff;
  width: 100%;

  height: 2.5rem;
}

.PublicButton1 {
  background: linear-gradient(
      318.86deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 53.86%
    ),
    radial-gradient(
      75% 100% at 103.31% 0%,
      #19d2ff 26.56%,
      rgba(16, 231, 251, 0) 100%
    ),
    radial-gradient(
      67.56% 67.56% at 0% 100%,
      #ffccfb 0%,
      #ff39f0 45.31%,
      rgba(238, 65, 255, 0) 150%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
  border-radius: 4px;
  border: 0px;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  color: #ffffff;
  width: 100%;

  height: 2.5rem;
}
.PublicButton1:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  background: linear-gradient(
      318.86deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 53.86%
    ),
    radial-gradient(
      58.26% 58.26% at 103.31% 0%,
      #19d2ff 26.56%,
      rgba(16, 231, 251, 0) 100%
    ),
    radial-gradient(
      67.56% 67.56% at 0% 100%,
      #ffccfb 0%,
      #ff39f0 45.31%,
      rgba(238, 65, 255, 0) 100%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
}
.StakePublicButton {
  background: linear-gradient(
      329.79deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 42.95%
    ),
    radial-gradient(
      58.26% 100.26% at 0% 103.31%,
      #ff39f0 26.56%,
      rgba(133, 66, 244, 0) 125%
    ),
    radial-gradient(
      67.56% 100.56% at 100% 0%,
      #fff4cc 0%,
      #ff9a36 45.31%,
      rgba(243, 127, 157, 0.22) 98%
    ),
    radial-gradient(
      83.68% 181.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
  border-radius: 4px;
  border: 0px;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  color: #ffffff;
  width: 18rem;
  height: 2.5rem;
  margin-left: 2rem;
  margin-top: 1rem;
}
.PublicButton:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  background: linear-gradient(
      329.79deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 42.95%
    ),
    radial-gradient(
      58.26% 100% at 103.31% 0%,
      #ff39f0 26.56%,
      rgba(133, 66, 244, 0) 125%
    ),
    radial-gradient(
      67.56% 100% at 0% 100%,
      #fff4cc 0%,
      #ff9a36 45.31%,
      rgba(243, 127, 157, 0.22) 100%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
}
.StakePublicButton:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  background: linear-gradient(
      329.79deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 42.95%
    ),
    radial-gradient(
      58.26% 100% at 103.31% 0%,
      #ff39f0 26.56%,
      rgba(133, 66, 244, 0) 125%
    ),
    radial-gradient(
      67.56% 100% at 0% 100%,
      #fff4cc 0%,
      #ff9a36 45.31%,
      rgba(243, 127, 157, 0.22) 100%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
}
@media only screen and (max-width: 768px) {
  .contracts a {
    font-size: 11px;
  }
  .PublicButton {
    width: 7rem;
  }
  .PublicButton1 {
    width: 7rem;
  }
  .header {
    text-align: center;
  }
  .logo {
    border-right: 0;
    left: 0;
  }
  .StakePublicButton {
    width: 13rem;
  }
}
