.BuyComponent {
  background: url("../images/bg.jpg");
  background-color: black;
  text-align: center;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  background-attachment: fixed;
}

.BuyComponent .logo-image {
  margin-bottom: 2rem;
}
/* .BuyComponent h2 {
  font-size: 24px;
  font-weight: bold;
  color: #3f355f;
} */

.BuyComponent h4 {
  font-size: 20px;
  font-weight: bold;
  color: #3f355fd3;
}

.BuyComponent .card-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 47px;
  color: #fff4cc;
  margin-bottom: 1rem;
}

.BuyComponent .sub-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #ff9a36;
}

.BuyComponent .accordian-card {
  background: transparent;
  border: none;
}
.BuyComponent .accordian-card .card-header {
  width: 31rem;
}

.BuyComponent .card-container {
  /* box-shadow: 0 4px 4px hsla(0, 0%, 76.9%, 0.3); */
  background-image: linear-gradient(
      342deg,
      rgba(50, 27, 159, 0.6) 0%,
      rgba(123, 35, 195, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      rgba(89, 2, 210, 0.6) 0%,
      rgba(133, 66, 244, 0) 110%
    );
  border-radius: 8px;
  padding: 2rem;
  width: 45%;
  opacity: 1.5;
  margin: 0 auto;
  display: flex;
  margin-top: 7rem;
  flex-direction: column;
  background-repeat: no-repeat;
}

.BuyComponent span {
  color: #3f355f;
  font-size: 14px;
  margin-bottom: 1rem;
}

.BuyComponent .buttonLink {
  display: inline-block;
  padding: 0.35em 1.2em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #323232;
  text-align: center;
  transition: all 0.2s;
  width: 32%;
  margin: 0 auto;
}

.BuyComponent .buttonLink:hover {
  color: white;
  background-color: #323232;
}

.BuyComponent .buttonBuy {
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #323232;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #323232;
  text-align: center;
  transition: all 0.2s;
  width: 32%;
  margin: 0 auto;
  margin-top: 1rem;
}

.BuyComponent .buttonBuy:hover {
  color: white;
  background-color: #323232;
}

.Faq {
  /* background: url(../images/bg.jpg); */
  /* width: 100%;*/
  /* height: 30rem; */
}

.Faq .small-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  margin-top: -2rem;
  margin-left: 5rem;
  /* identical to box height, or 161% */

  /* White / 300 */

  color: #f3f2f9;
}

.vector-img {
  margin-top: 2rem;
}
.BuyComponent .inputBuy {
  display: inline-block;
  padding: 0.35em 0.3em;
  height: 2.4rem;
  width: 100%;
  background: linear-gradient(318.86deg, #010013, rgba(1, 0, 19, 0.1) 53.86%),
    radial-gradient(83.68% 83.68% at 0 0, #010013 0, rgba(1, 0, 19, 0.2) 95.83%);
  border-radius: 2px;
  border: 1px solid;
  border-image-source: linear-gradient(
    114.3deg,
    #c69fff,
    rgba(198, 159, 255, 0) 48.44%,
    #c69fff
  );
  border-image-slice: 1;
  margin-top: 1rem;
  padding-top: 4px;
  padding-left: 0.5rem;
  border-color: #7151e1;
  font-size: 12px;
  /* outline: none; */
  background-color: transparent;
  /* border: none; */
  /* border-bottom: 1px solid; */
  color: white;
  
}
.BuyComponent .inputBuy::placeholder {
  text-align: left;
  color: white;
}
.BuyComponent .inputBuy:focus-visible {
  border-radius: 2px;
  border: 1px solid;
  border-image-source: linear-gradient(
    114.3deg,
    #c69fff,
    rgba(198, 159, 255, 0) 48.44%,
    #c69fff
  );
  border-image-slice: 1;
}
.BuyComponent .vesting-done {
  background: #21965452;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  padding: 0.7rem 1rem;
  text-align: center;
  border-radius: 32px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.44px;
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
}

.BuyComponent .error {
  color: red;
}

.BuyComponent .termscheck {
  margin-right: 5px;
}
.BuyComponent .table-heading {
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 29px;
  color: #f3f2f9;
  font-family: "Chillax-Variable" !important;
}
.BuyComponent .small-heading {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height, or 161% */

  /* White / 300 */

  color: #f3f2f9;
}
.BuyComponent .heading-data {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #f3f2f9;
  font-family: "Ranade-Variable" !important;
}
.BuyComponent .heading-info {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  font-family: "Ranade-Variable" !important;
  /* identical to box height, or 161% */

  text-align: center;

  /* Color / White */

  color: #ffffff;
}
.BuyComponent .PrivateButton {
  background: linear-gradient(
      318.86deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 53.86%
    ),
    radial-gradient(
      75% 100% at 103.31% 0%,
      #19d2ff 26.56%,
      rgba(16, 231, 251, 0) 100%
    ),
    radial-gradient(
      67.56% 67.56% at 0% 100%,
      #ffccfb 0%,
      #ff39f0 45.31%,
      rgba(238, 65, 255, 0) 150%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  border: 0px;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  color: #ffffff;
  width: 12rem;
  height: 2.5rem;
}
.BuyComponent .PrivateButton:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  background: linear-gradient(
      318.86deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 53.86%
    ),
    radial-gradient(
      75.26% 100.26% at 0% 103.31%,
      #19d2ff 26.56%,
      rgba(16, 231, 251, 0) 100%
    ),
    radial-gradient(
      67.56% 67.56% at 100% 0%,
      #ffccfb 0%,
      #ff39f0 45.31%,
      rgba(238, 65, 255, 0) 150%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
}
.union-img {
  margin-top: 3rem;
}
.learn-img {
  float: left;
  margin-top: 4rem;
  margin-left: 5rem;
}
.learn-img:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.BuyComponent .PublicButton {
  background: linear-gradient(
      329.79deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 42.95%
    ),
    radial-gradient(
      58.26% 100.26% at 0% 103.31%,
      #ff39f0 26.56%,
      rgba(133, 66, 244, 0) 125%
    ),
    radial-gradient(
      67.56% 100.56% at 100% 0%,
      #fff4cc 0%,
      #ff9a36 45.31%,
      rgba(243, 127, 157, 0.22) 98%
    ),
    radial-gradient(
      83.68% 181.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  color: #ffffff;
  width: 12rem;
  height: 2.5rem;
}
.BuyComponent .PublicButton:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  background: linear-gradient(
      329.79deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 42.95%
    ),
    radial-gradient(
      58.26% 100% at 103.31% 0%,
      #ff39f0 26.56%,
      rgba(133, 66, 244, 0) 125%
    ),
    radial-gradient(
      67.56% 100% at 0% 100%,
      #fff4cc 0%,
      #ff9a36 45.31%,
      rgba(243, 127, 157, 0.22) 100%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
}
@media all and (max-width: 30em) {
  .BuyComponent .buttonBuy {
    display: block;
    margin: 0.2em auto;
  }
}
@media only screen and (max-width: 1025px) {
  .BuyComponent .PublicButton {
    width: 7rem;
    font-size: 13px;
  }
  .BuyComponent .PrivateButton {
    width: 7rem;
    font-size: 11px;
  }
  .BuyComponent .card-container-main {
    width: 70% !important ;
    margin-top: 4rem;
  }
  .union-img {
    width: 22rem;
  }
  .learn-img {
    width: 8rem;
    margin-top: 2rem;
    margin-left: 4rem;
  }
  .vector-img {
    margin-top: 2rem;
    width: 15rem;
    height: auto;
  }
  .address-link {
    font-size: 8px;
  }
  .Faq .small-heading {
    font-size: 9px;
    margin-top: -1rem;
    margin-left: -1rem;
  }
  .BuyComponent .logo-image {
    width: 9rem;
  }
  .BuyComponent .card-container {
    width: 80%;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
@media only screen and (max-width: 470px) {
  .vector-img {
    display: none;
  }
}
