.footer-container {
  display: flex;
  width: 100%;
  background-color: transparent;
  color: #878dad;
  padding: 1rem 1.5rem;
  font-family: Ranade;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-top: 5rem;
  font-family: "Chillax-Medium" !important;
  border-top: 1px solid rgba(225, 225, 225, 0.3);
}

.footer-container .footer-right {
  display: flex;
}
.footer-container .footer-div {
  display: flex;
  flex-grow: 1;
}

.footer-container .argo {
  width: 1.8vw;
  height: auto;
  margin-right: 0.5rem;
}
.footer-container .zepplin {
  height: 2vh;
  width: auto;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
}

.footer-container .footer-right .footer-icon {
  color: #878dad;
  font-size: 16px;
  display: flex;
  cursor: pointer;
  margin-left: 1rem;
}
.footer-container .footer-div .argo-div {
  margin-left: 2rem;
}

.footer-container .footer-right .footer-icon:hover {
  color: #fff;
}
@media only screen and (max-width: 1025px) {
  .footer-container .footer-right .footer-icon {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .footer-container .footer-right {
    display: grid;
  }
  .footer-container .footer-div {
    display: grid;
    justify-content: flex-start;
  }
  .footer-container .footer-div .argo-div {
    margin-left: -8rem;
  }
  .footer-container .argo {
    width: 5.5vw;
    height: auto;
  }
  .footer-container .zepplin {
    width: auto;
    height: 1.5vh;
    margin-top: 0.2rem;
  }
}
